import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.scss";
import "../../components/Account-setup/AccountSetup.scss";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { CurrentUser } from "../../utils/redux/Auth/AuthSlice";
import { GetLocalStorageUser } from "../../utils/GlobalUtils/GetLocalStorageValues";

export const GreetUser = () => {
  const currentTime = new Date().getTime();
  if (!currentTime) {
    return "Hello";
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(new Date().getHours());

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return "Good Afternoon";
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return "Good Evening";
  }
  // Between dawn and noon
  return "Good Morning";
};

export default function DashboardTemplate({ children }) {
  const { pendingVerifications, firstName } = GetLocalStorageUser();
  const dispatch = useDispatch();
  useEffect(() => {
    // getDetails();
    dispatch(CurrentUser());
  }, [pendingVerifications, dispatch]);

  return (
    <section>
      <>
        <nav>
          <Grid container spacing={2} className="dash-top">
            <Grid
              className="dash-top-greet"
              sx={{
                paddingLeft: "1rem",
              }}
            >
              <Typography className="greet">
                <h2>
                  {GreetUser()}, {firstName}!
                </h2>
              </Typography>
              <Typography className="greettwo">
                Welcome back, nice to see you again!
              </Typography>
            </Grid>

            <Grid item className="dash-top-details"></Grid>
          </Grid>
        </nav>

        {children}
      </>
    </section>
  );
}
