import React from "react";
import "./SuccessDisplay.css";
import SuccessGIF from "../../../assets/img/success.gif";

function SuccessInModalDisplay({ button, children, action }) {
  return (
    <div className="message-contentx">
      <div className="message-container">
        <img src={SuccessGIF} alt="Success GIF" />
        <div className="message-for-user">{children}</div>
        <button type={"button"} onClick={() => action?.()}>
          {button}
        </button>
      </div>
    </div>
  );
}

export default SuccessInModalDisplay;
