import api, { formApi } from "../api";

const UsersModule = () => {
  return {
    AddNewWallet: function (data) {
      return api.post(`identity/Agent/subwallet`, data);
    },

    ChangePin: function (data) {
      return api.post("identity/Agent/changepin", data);
    },

    //Agents
    SuperdealerAgents: function (data) {
      return api.get("identity/Superdealer/agents", data);
    },

    SingleSuperdealerAgents: function (data) {
      return api.get(`identity/Superdealer/agents/${data}?id=${data}`, data);
    },

    UpdateSuperdealerAgents: function (data) {
      return api.post(`identity/Superdealer/agents/updateagent`, data);
    },

    GetSuperdealerAgentsProducts: function (data) {
      return api.get(
        `identity/Superdealer/products/agent/${data}?agentId=${data}`,
        data
      );
    },

    //Resellers
    SuperdealerResellers: function (data) {
      return api.get("identity/Superdealer/reseller", data);
    },

    SingleSuperdealerResellers: function (data) {
      return api.get(`identity/Superdealer/reseller/${data}?id=${data}`, data);
    },

    // UpdateSuperdealerReseller: function (data) {
    //   return api.post(`identity/Admin/superdealer/agents/update`, data);
    // },

    // /Agent/update/personalinformation
    UpdateSuperdealerReseller: function (data) {
      return api.post(`identity/Agent/reseller/agents/update`, data);
    },

    UpdatePersonalInformation: function (data) {
      return api.post(`identity/Agent/update/personalInformation`, data);
    },

    GetSuperdealerResellerProducts: function (data) {
      return api.get(
        `identity/Superdealer/products/reseller/${data}?agentId=${data}`,
        data
      );
    },

    BulkUploadAgent: function ({ agentRole, fileData }) {
      //console.log("dataaaa", { agentRole, fileData });
      return formApi.post(`identity/Agent/uploadagents/${agentRole}`, fileData);
    },

    AgentSearch: function (data) {
      return api.get(
        `identity/Agent/agents/search?EmailAddress=${data.email}&MobileNumber=${data.phone}`
      );
    },

    // agent/reseller/prod÷ucts
    //products
    getProducts: function (data) {
      return api.get(`identity/agent/reseller/products`);
    },

    CustomerProductsRessellerAndAgent: function (data) {
      return api.get(`identity/Agent/downline/product/${data}`, data);
    },

    ListCustomerWallets: function (data) {
      ////console.log("tttttttt", data);
      return api.get(`/identity/Agent/subwallet/${data}?id=${data}`);
    },

    //products
    getProductsWithWalletId: function (data) {
      return api.get(`identity/AgentProduct/downline/${data}`);
    },

    // /Agent/reseller/onboardagent
    OnboardAgent: function (data) {
      return api.post("identity/Agent/reseller/onboardagent", data);
    },

    // EnableAction
    EnableAction: function (data) {
      const { AgentId, Type } = data;
      return api.post(
        `identity/Agent/downline/settings/activate?AgentId=${AgentId}&Activate=true&Type=${Type}`
      );
    },

    // DisableAction
    DisableAction: function (data) {
      const { AgentId, Type } = data;
      //console.log("holy", data);
      return api.post(
        `identity/Agent/downline/settings/deactivate?AgentId=${AgentId}&Activate=false&Type=${Type}`
      );
    },

    // ActivateCustomers
    ActivateCustomers: function (data) {
      return api.post("identity/Admin/agents/activateuser", data);
    },

    // DeactivateCustomers
    DeactivateCustomers: function (data) {
      return api.post("identity/Admin/agents/deactivateuser", data);
    },

    // AddMoreProducts
    AddMoreProducts: function (data) {
      return api.post(
        "identity/Admin/superdealer/agents/products/create",
        data
      );
    },

    // Customerroducts
    CustomerProductsAgent: function (data) {
      // return api.get(`identity/ProductAdmin/products/agent/${data}`, data);
      return api.get(`identity/agent/downline/product/${data}`, data);
    },

    // Customerroducts
    CustomerProductsResseller: function (data) {
      // return api.get(`identity/ProductAdmin/products/reseller/${data}`, data);
      return api.get(`identity/agent/downline/product/${data}`, data);
    },

    // UpdateCustomerProducts
    UpdateCustomerProducts: function (data) {
      return api.post(`identity/AgentProduct/agent/status/update`, data);
    },

    // /Admin/superdealer/agents/product/commission/update
    // UpdateCustomerCommission: function (data) {
    //   return api.post(
    //     `identity/Admin/superdealer/agents/product/commission/update`,
    //     data
    //   );
    // },

    // /AgentProduct/products/update/agent/5071
    UpdateCustomerCommission: function (data) {
      return api.post(
        `identity/AgentProduct/products/update/agent/${data.walletId}`,
        data
      );
    },

    // UpdateCommission
    UpdateCommission: function (data) {
      return api.post(`/identity/Agent/product/bulkupdate`, data);
    },

    //list of customers
    ListCustomers: function (data) {
      ////console.log("tttttttt", data);
      return api.get(
        `identity/Agent/tellers/paged?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&SearchText=${data.keyword}`
      );
    },
  };
};

export const UsersService = {
  usersModule: UsersModule,
};
