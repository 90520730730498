import React from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function Graph(props) {
  const { graphData } = props;

  const graphValues = {
    "Total Commission": graphData?.totalCommission,
    "Total Transaction Count": graphData?.totalTransactionCount,
    "Total Transaction Volume": graphData?.totalTransactionVolume,
  };

  const options = {
    responsive: true,

    plugins: {
      legend: {
        position: "right",
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
    },
    options: {
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: "Month",
            color: "#911",
            font: {
              family: "Inter",
              size: 20,
              weight: "bold",
              lineHeight: 1.2,
            },
            padding: { top: 20, left: 0, right: 0, bottom: 0 },
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              console.log({ value });
              return "$" + value;
            },
          },
        },
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return "$" + value;
            },
          },
        },
      },
    },
  };

  const labels = Object.keys(graphValues);

  const dataName = {
    labels,
    datasets: [
      {
        label: "Commission, Volume and Count Comparism",
        data: Object.values(graphValues),
        backgroundColor: ["#CC2B28", "#09847E", "#F8DE5D"],
      },
    ],
  };
  return (
    <>
      <Pie
        style={{ width: "30%", height: "30%" }}
        options={options}
        data={dataName}
      />
    </>
  );
}

export default Graph;
