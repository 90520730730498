import React from "react";
import "./SuccessDisplay.css";
import SuccessGIF from "../../../assets/img/success.gif";
import { useOTPModal } from "../../../components/OTPModal/OTPModal";

function SuccessDisplay({ message, subMessage, action, dontReload }) {
  const { successMessage, success } = useOTPModal();

  if (success === true) {
    return (
      <div className="success-display">
        <div className="message-content">
          <img src={SuccessGIF} alt="Success GIF" />
          <h5>{successMessage || message}</h5>
          {subMessage && <p className={"subMessage"}>{subMessage}</p>}
          <button
            type="button"
            onClick={() => {
              if (action) {
                action();
                dontReload !== true && window?.location?.reload();
              } else window?.location?.reload();
            }}
          >
            Ok
          </button>
        </div>
      </div>
    );
  }
}

export default SuccessDisplay;
