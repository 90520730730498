import React, {
  useState,
  Fragment,
  useLayoutEffect,
  useContext,
  useEffect,
} from "react";
import "./CommonStyle/CommonStyle.css";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { DrawerURLs } from "../Drawer/DrawerContext/DrawerURLs";
import { useLocation } from "react-router";
import { theme } from "../../context/ThemeProvider";
import { AppContext } from "../../utils/AppContext/AppContext";

export const SiteParams = (props) => {
  const { SiteRoute } = useContext(AppContext);
  const selectedDrawer = DrawerURLs()?.Urls?.filter(
    (x) => x?.name === props?.text
  )[0];

  const selectedDrawerChildren = DrawerURLs()?.Urls?.filter(
    (x) => x?.name === props?.text
  )[0]?.children;

  const location = useLocation();
  const presentURL =
    location?.pathname?.split("/")[2] || location?.pathname?.split("/")[1];

  const presentDrawerURL =
    selectedDrawer?.href?.split("/")[2] ||
    selectedDrawer?.href?.split("/")[1] ||
    selectedDrawerChildren?.[0]?.href?.split("/")[2];
  const childURL = location?.pathname?.split("/")[3];

  useEffect(() => {
    SiteRoute({
      presentURL,
      presentDrawerURL,
      childURL,
      selectedDrawer,
      selectedDrawerChildren,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedDrawer,
    selectedDrawerChildren,
    presentURL,
    presentDrawerURL,
    childURL,
  };
};

const CustomListItem = (props) => {
  const [open, setOpen] = useState(false);
  const { presentURL, presentDrawerURL, childURL } = SiteParams(props);

  // console.log(props);

  const handleClick = (propOnClick) => {
    return (ev) => {
      if (propOnClick) {
        propOnClick(ev);
      }

      setOpen(!open);
    };
  };

  const moreIcon = open ? (
    <ExpandLess className="expandless-button" />
  ) : (
    <ExpandMore className="expandless-button" />
  );

  useLayoutEffect(() => {
    presentURL === presentDrawerURL && setOpen(!open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentDrawerURL]);
  return (
    <Fragment>
      <ListItemButton
        className={`list-button ${
          presentURL === presentDrawerURL && "list-button-active"
        }  ${
          props?.href?.split?.("/")?.[3] === childURL &&
          "list-button-child-active"
        }`}
        selected={!!props.selected}
        onClick={handleClick(props.onClick)}
        href={props.href || null}
        // style={{
        //   backgroundColor: presentURL === presentDrawerURL && "red",
        // }}
      >
        <ListItemIcon className="list-icons">{props.icon}</ListItemIcon>
        <ListItemText className="list-item-text" primary={props.text || ""} />
        {!props.children ? null : moreIcon}
      </ListItemButton>
      {!props.children ? null : (
        <Collapse
          className="list-collapse"
          in={open}
          timeout="auto"
          unmountOnExit
        >
          {props.children}
        </Collapse>
      )}
    </Fragment>
  );
};

export default CustomListItem;
