import React from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./Onboarding.css";

function PasswordRevealer({ show, onClick, className }) {
  return (
    <div className={`password-revealer ${className}`} onClick={() => onClick()}>
      {show ? <BsEye fontSize={21} /> : <BsEyeSlash fontSize={21} />}
    </div>
  );
}

export default PasswordRevealer;
