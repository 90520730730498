import { Box } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import "./SetPin.scss";
import "./SettlementBank.css"
import { useDispatch, useSelector } from "react-redux";
import { SetNewPin } from "../../../../utils/redux/Settings/SettingsSlice";
import SuccessDisplay from "../../../../layouts/Settings/Success/SuccessInModalDisplay";
import { CurrentUser } from "../../../../utils/redux/Auth/AuthSlice";
import PasswordRevealer from "../../../../pages/authPages/onboarding/PasswordRevealer";

const SetPin = ({ onClose }) => {
  const [successState, setSuccessState] = useState(false);
  const [showPassword, setShowPassoword] = useState(false);
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state);

  return (
    <div className="settlement-bank-wrapper">
      <Box className="personal-info-title">
        <h4>Set-up Your PIN</h4>
        <p>Create a 4 digit transaction PIN</p>
      </Box>
      <Box className="personal-info-body">
        <Formik
          initialValues={{
            setPin: "",
            confirmPin: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              SetNewPin({
                newPin: values?.setPin,
                confirmPin: values?.setPin,
              })
            ).then((res) => {
              if (res?.payload?.isSuccessful === true) {
                setSuccessState(!successState);
                dispatch(CurrentUser());
              }
            });
          }}
          validationSchema={Yup.object({
            setPin: Yup.string()
              .max(4, "Pin must not be more thann 4 digits.")
              .required("Pin is required")
              .matches(/^[0-9]+$/, "Pin must include only numbers"),
            confirmPin: Yup.string()
              .required("Confirm Pin is required")
              .oneOf([Yup.ref("setPin"), null], "Pin must match"),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              {successState && (
                <SuccessDisplay
                  button={"OK"}
                  action={() => {
                    setSuccessState(!successState);
                    onClose();
                    window.location.reload();
                  }}
                >
                  <h4
                    style={{
                      color: "var(---primary-color)",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    PIN created successfully.
                  </h4>
                </SuccessDisplay>
              )}
              <div className="info-form-wrapper">
                <label>
                  PIN (Four (4)) Digit <span>*</span>
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="setPin"
                  name="setPin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.setPin}
                  className={
                    touched.setPin && errors.setPin ? "info-error" : null
                  }
                />
                {errors.setPin && touched.setPin && (
                  <span className="error">{errors.setPin}</span>
                )}
                <PasswordRevealer
                  show={showPassword}
                  className={"password_revealer_pin"}
                  onClick={() => setShowPassoword(!showPassword)}
                />
              </div>

              <div className="info-form-wrapper">
                <label>
                  Confirm PIN <span>*</span>
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirmPin"
                  name="confirmPin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPin}
                  className={
                    touched.confirmPin && errors.confirmPin
                      ? "info-error"
                      : null
                  }
                />
                {errors.confirmPin && touched.confirmPin && (
                  <span className="error">{errors.confirmPin}</span>
                )}
                <PasswordRevealer
                  show={showPassword}
                  className={"password_revealer_pin"}
                  onClick={() => setShowPassoword(!showPassword)}
                />
              </div>

              <div className="submit-btn">
                <button type="submit">
                  {settings?.loading ? "Validating..." : "Validate"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default SetPin;
