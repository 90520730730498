export const CreateQueryParams = (params) => {
  const queryString = Object.keys(params)
    .filter((key) => params[key])
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

  return `?${queryString}`;
};
