import React from "react";
import "./style/style.css";
import "./style/react-datepicker.css";
import { IoFilter } from "react-icons/io5";

function FilterButton({ value, onChange, name, className, onClick, ...style }) {
  return (
    <button
      onClick={() => onClick?.()}
      style={{ style }}
      className={`filter_comp_container ${className}`}
    >
      <IoFilter size={18} color={"#171832"} />
      Filter
    </button>
  );
}

export default FilterButton;
