export const USER_TYPE = {
  AGENT: 4,
  TELLER: 7,
};

export const REGEX = {
  EMAIL: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
};

export const BANKING_CONFIG = {
  ACCOUNT_LENGHT: 13,
};
