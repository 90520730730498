export const PerformanceCount = (data) => {
  const count = data?.count;
  const volume = data?.volume;
  const commission = data?.commission;

  const XObjectHelper = (place) =>
    Object?.entries?.(place || {})
      ?.slice?.(1, place?.length)
      ?.map?.((x) => {
        return (
          {
            agent_amount: x?.[1]?.agent || 0,
            reseller_amount: x?.[1]?.reseller || 0,
          } || {}
        );
      });

  const YObjectHelper = (place) =>
    Object?.entries?.(place || {})
      ?.slice?.(1, place?.length)
      ?.map?.((x) => x?.[0]?.charAt(0)?.toUpperCase() + x?.[0]?.slice(1));

  return {
    count: {
      today: {
        number: count?.today?.total_count || 0,
        x_axis: XObjectHelper(count?.today),
        y_axis: YObjectHelper(count?.today),
      },
      yesterday: {
        number: count?.yesterday?.total_count || 0,
        x_axis: XObjectHelper(count?.yesterday),
        y_axis: YObjectHelper(count?.yesterday),
      },
      last_month: {
        number: count?.last_month?.total_count || 0,
        x_axis: XObjectHelper(count?.last_month),
        y_axis: YObjectHelper(count?.last_month),
      },
    },
    volume: {
      today: {
        number: volume?.today?.total_volume || 0,
        x_axis: XObjectHelper(volume?.today),
        y_axis: YObjectHelper(volume?.today),
      },
      yesterday: {
        number: volume?.yesterday?.total_volume || 0,
        x_axis: XObjectHelper(volume?.yesterday),
        y_axis: YObjectHelper(volume?.yesterday),
      },
      last_month: {
        number: volume?.last_month?.total_volume || 0,
        x_axis: XObjectHelper(volume?.last_month),
        y_axis: YObjectHelper(volume?.last_month),
      },
    },
    commission: {
      today: {
        number: commission?.today?.total_commission || 0,
        x_axis: XObjectHelper(commission?.today),
        y_axis: YObjectHelper(commission?.today),
      },
      yesterday: {
        number: commission?.yesterday?.total_commission || 0,
        x_axis: XObjectHelper(commission?.yesterday),
        y_axis: YObjectHelper(commission?.yesterday),
      },
      last_month: {
        number: commission?.last_month?.total_commission || 0,
        x_axis: XObjectHelper(commission?.last_month),
        y_axis: YObjectHelper(commission?.last_month),
      },
    },
  };
};
