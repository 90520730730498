export const GetLocalStorageUser = (params) => {
  const value = JSON.parse(localStorage.getItem(params || "user"));
  return value;
};

export const GetLongitudeLatitude = (params) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        localStorage.setItem(
          "location",
          JSON.stringify(
            {
              latitude,
              longitude,
            },
            null,
            2
          )
        );
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
  }
};
