import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import "./Perfomance/performance.css";
import { GoDotFill } from "react-icons/go";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Graph from "./Graph/Graph";
import { FormatCurrency } from "../../utils/GlobalUtils/Currency";
import { PerformanceCount } from "./Perfomance/PerformanceHelpers";
import DateComponent from "../../components/datespicker/DatePicker";
import DropDownComponent from "../../components/dropdown/DropDownComponent";
import FilterButton from "../../components/search/FilterButton";
import { GetAccessAgentTransactionsCharts } from "../../utils/redux/Reports/ReportsSlice";
import { statusProvider } from "../Report/TransactionListing";
import { FormatDateTime } from "../../utils/GlobalUtils/DateFormater";



export default function DBPerformanceTracking({ fromDashboard }) {
  const { agent_transactions } = useSelector((state) => state?.reports);

  const [startDate, setStartDate] = useState(new Date());

  const [value, setValue] = useState("");
  const [submit, setSubmit] = useState(false);

  const graphData = useSelector((x) => x?.performance);
  const performance = graphData?.performance;

  const Count = PerformanceCount(performance);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      GetAccessAgentTransactionsCharts({
        TransactionDate: FormatDateTime(startDate, "YYYY-MM-DD"),
        TransactionType: value?.toUpperCase(),
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const GraphTitle = (title) => {
    return <p className="graph-name">{title}</p>;
  };

  const GraphTag = (name, color) => {
    return (
      <div className="tag-graph">
        <span>
          <GoDotFill style={{ color: color }} />
        </span>
        <p>{name}</p>
      </div>
    );
  };

  const GraphCage = (name, graphName, isCount, data) => {
    return (
      <Box className="traded-volume trd-rslle">
        <Box className="traded-volume-top">
          <Box className="traded-title">{name}</Box>
          {/* <DaySwitch v={name} /> */}
        </Box>
        <div className="peformance_group">
          {" "}
          <div style={{ width: "50%", margin: "" }}>
            {" "}
            <Graph graphData={agent_transactions?.responseModel} />
          </div>
          <div className="traded-volume-value-div">
            {/* <div className={"traded-volume-value"}>{GraphTitle(graphName)}</div> */}
            <div className="value-graph graph-iconsy">
              <div>
                <div> {GraphTag("Count", "#09847E")}</div>
                <small>
                  {agent_transactions?.responseModel?.totalTransactionCount}
                </small>
              </div>
              <div>
                <div>{GraphTag("Volume", "#F8DE5D")}</div>
                <small>
                  {FormatCurrency(
                    agent_transactions?.responseModel?.totalTransactionVolume
                  )}
                </small>
              </div>
              <div>
                <div> {GraphTag("Commission", "#CC2B28")}</div>
                <small>
                  {FormatCurrency(
                    agent_transactions?.responseModel?.totalCommission?.toFixed(
                      2
                    )
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <section style={{ backgroundColor: "white", padding: "20px" }}>
      {graphData?.loading === true && "Loading..."}
      <div
        style={{
          display: "inline-flex",
          justifyContent: "left",
          alignItems: "center",
          width: "100%",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <DropDownComponent
          array={statusProvider}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          name={"demo"}
        />
        <DateComponent
          date={startDate}
          setDate={setStartDate}
          maxDate={new Date()}
        />
        <FilterButton onClick={() => setSubmit(!submit)} />
      </div>

      <Box className="volume-agent-wrapper performance">
        <Grid container className="volume-agent-container">
          <Grid item xs={12} md={12} margin={"0 auto"}>
            {GraphCage("My Performance", "", true, Count?.my_performance)}
          </Grid>
        </Grid>
      </Box>
    </section>
  );
}
