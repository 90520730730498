import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import OtpInput from "react-otp-input";
import "./OTPModal.css";
import { IconButton, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { AppContext } from "../../utils/AppContext/AppContext";

export const buttonStyle = {
  padding: "0.6rem",
  textTransform: "capitalize",
  boxShadow: "none",
  width: "100%",
  "&.MuiButton-contained": {
    backgroundColor: "var(--primary-color)",
    color: "white",
    "&:disabled": {
      color: "white",
    },
  },

  "&.MuiButton-outlined": {
    border: "1px solid var(--bright-blue)",
    color: "(--text-primary-color)",
  },
};

export function useOTPModal() {
  const {
    startAction,
    endAction,
    details,
    isOpen,
    open,
    successMessage,
    closeAppModal,
    SiteRoute,
    routes,
    openSuccess,
    closeSuccess,
    success,
  } = useContext(AppContext);
  return {
    startAction,
    endAction,
    details,
    isOpen,
    open,
    successMessage,
    closeAppModal,
    SiteRoute,
    routes,
    openSuccess,
    closeSuccess,
    success,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    borderRadius: "6px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const closeIconStyle = {
  position: "absolute",
  right: 14,
  top: 14,
  color: "var(--text-primary-color)",
  border: "2px solid var(--text-primary-color)",
  p: 0.2,
};

const OTPModal = (props) => {
  const { loading } = useSelector((x) => x?.settings);
  const { buyingAirtime, buyingElectricity, buyingData } = useSelector(
    (state) => state?.products
  );

  const authorize = buyingAirtime || buyingElectricity || buyingData;
  const { isOpen, endAction, details } = useOTPModal();
  const { submit } = details;
  const [otp, setOtp] = useState();

  const handleOtp = (otp) => {
    setOtp(otp);
  };

  const handleClose = () => {
    endAction();
    setOtp("");
  };

  const onClickAuthorize = () => {
    submit(otp);
  };

  return (
    <>
      {isOpen && (
        <>
          <BootstrapDialog open={true} onClose={handleClose}>
            <DialogTitle className="dd" sx={{ m: 0, p: 3 }}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  ...closeIconStyle,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Stack spacing={1.75} direction="column">
                <h2 className="otp_modal_title">
                  Enter transaction PIN <span>*</span>
                </h2>
                <OtpInput
                  onChange={handleOtp}
                  shouldAutoFocus={true}
                  value={otp}
                  numInputs={4}
                  isInputSecure={true}
                  separator={
                    <span>
                      <strong></strong>
                    </span>
                  }
                  placeholder="****"
                  inputStyle={{
                    width: "3.1rem",
                    height: "3.1rem",
                    //     margin: "0 10px",
                    fontSize: "2rem",
                    borderRadius: 4,
                    border: "1px solid #8A92A6",
                    padding: "0.5rem",
                    background: "#ffffff",
                  }}
                  containerStyle={{
                    display: "flex",
                    gap: "1rem",
                    alignItem: "center",
                    justifyContent: "space-around",
                  }}
                />
                <Button
                  disabled={loading || otp?.length !== 4}
                  onClick={() => {
                    onClickAuthorize();
                  }}
                  variant="contained"
                  sx={{ ...buttonStyle }}
                >
                  {authorize === true ? "Authorizing..." : "Authorize"}
                </Button>
                <Button
                  sx={{ ...buttonStyle }}
                  onClick={handleClose}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Stack>
            </DialogContent>
          </BootstrapDialog>
        </>
      )}
    </>
  );
};

export default OTPModal;
