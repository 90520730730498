import React from "react";
import Main from "./Main";
import OTPModal from "./components/OTPModal/OTPModal";
import {  AppProvider } from "./utils/AppContext/AppContext";
import SuccessDisplay from "./layouts/Settings/Success/SuccessDisplay";

function App() {
  return (
    <AppProvider>
      <div>
        <Main />
        <OTPModal />
        <SuccessDisplay />
      </div>
    </AppProvider>
  );
}

export default App;
