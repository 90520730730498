import React from "react";
import "./style/style.css";
import "./style/react-datepicker.css";
import { FiChevronDown } from "react-icons/fi";

function DropDownComponent({ value, onChange, name, array, maxWidth }) {
  return (
    <div className="dd_comp_cont">
      <div
        className="dropdown_comp_container"
        style={{ maxWidth: maxWidth || "" }}
      >
        <select value={value} onChange={onChange} name={name} id={name}>
          {array?.map?.((item, key) => {
            return (
              <option key={Math.random()} value={item?.key}>
                {item?.name}
              </option>
            );
          })}
        </select>
        <FiChevronDown size={18} className="outline_calendar" />
      </div>
    </div>
  );
}

export default DropDownComponent;
