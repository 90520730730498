import {
  FiBox,
  FiHome,
  FiPieChart,
  // FiPieChart,
  FiSettings,
  FiUser,
  // FiFolderPlus
  // FiUserCheck,
} from "react-icons/fi";
import { GetLocalStorageUser } from "../../../utils/GlobalUtils/GetLocalStorageValues";
import { USER_TYPE } from "../../../utils/GlobalUtils/Enums";
import { URLs } from "./URLEnums";

const allResellerRoutes = ["Teller Listing", "Performance"];

export const DrawerURLs = () => {
  const { agentRole } = GetLocalStorageUser();

  const URLValues = {
    Urls: [
      {
        icon: <FiHome />,
        name: "Dashboard",
        hasChildren: false,
        href: "/dashboard",
        isDashboard: true,
      },
      // {
      //   icon: <FiHome />,
      //   name: "Wallets",
      //   hasChildren: false,
      //   href: "/dashboard/wallets",
      // },
      {
        icon: <FiUser />,
        name: "Teller Listing",
        hasChildren: false,
        href: `/dashboard/${URLs.TELLER_MGT}`,
        // children: [
        //   {
        //     name: "Teller",
        //     href: `/dashboard/${URLs.TELLER_MGT}/${URLs.TELLER_PAGE}`,
        //   },
        // ],
      },
      // {
      //   icon: <BiBox />,
      //   name: "Funding",
      //   hasChildren: true,
      //   href: "",
      //   children: [
      //     {
      //       name: "Funding Customer Wallet",
      //       href: "/dashboard/funding/customer-wallet",
      //     },
      //   ],
      // },
      // {
      //   icon: <FiUserCheck />,
      //   name: "Debit Customer",
      //   hasChildren: false,
      //   href: "/dashboard/debit-customer-module",
      // },

      {
        icon: <FiBox />,
        name: "Products",
        hasChildren: true,
        href: "",
        children: [
          {
            name: "Airtime",
            href: "/dashboard/products/airtime",
          },
          // {
          //   name: "Data",
          //   href: "/dashboard/products/data",
          // },
          {
            name: "EDSA",
            href: "/dashboard/products/electricity",
          },
          // {
          //   name: "Cable TV",
          //   href: "/dashboard/products/cable-television",
          // },
        ],
      },
      {
        icon: <FiBox />,
        name: "Agency Banking",
        hasChildren: true,
        href: "",
        children: [
          // {
          //   name: "Fund Wallet",
          //   href: "/dashboard/agency-banking/fund",
          // },
          {
            name: "Transfer",
            href: "/dashboard/agency-banking/transfer",
          },
          {
            name: "Cash Out",
            href: "/dashboard/agency-banking/cash-out",
          },
          {
            name: "Access Africa",
            href: "/dashboard/agency-banking/access-africa",
          },
          // {
          //   name: "Withdraw Money",
          //   href: "/dashboard/agency-banking/wallet",
          // },
        ],
      },
      // {
      //   icon: <FiBox />,
      //   name: "Invoicing",
      //   hasChildren: true,
      //   href: "",
      //   children: [
      //     {
      //       name: "Open Invoice",
      //       href: "/dashboard/invoice/open",
      //     },
      //     {
      //       name: "Closed Invoice",
      //       href: "/dashboard/invoice/closed",
      //     },
      //   ],
      // },
      {
        icon: <FiPieChart />,
        name: "Report",
        hasChildren: true,
        href: "",
        children: [
          // {
          //   name: "Agent Listing",
          //   href: "/dashboard/report/agent-listing",
          // },
          {
            name: "Transaction Listing",
            href: "/dashboard/report/transaction-listing",
          },
          // {
          //   name: "Transaction Summary",
          //   href: "/dashboard/report/transaction-summary",
          // },
          // {
          //   name: "Commission Wallet",
          //   href: "/dashboard/report/commission-wallet",
          // },

          {
            name: "Airtime",
            href: "/dashboard/report/airtime-report",
          },
          // {
          //   name: "Data",
          //   href: "/dashboard/report/data-report",
          // },
          {
            name: "EDSA",
            href: "/dashboard/report/bills-report",
          },
          {
            name: "Access Africa",
            href: "/dashboard/report/access-africa",
          },
          {
            name: "Cashout",
            href: "/dashboard/report/main-wallet",
          },
          {
            name: "Transfer",
            href: "/dashboard/report/transfer",
          },

          {
            name: "Pending Transactions",
            href: "/dashboard/report/pending",
          },
        ],
      },
      // {
      //   icon: <FiHome />,
      //   name: "Performance",
      //   hasChildren: false,
      //   href: "/dashboard/performance",
      // },
      // // {
      // //   icon: <HiOutlineDocumentReport />,
      // //   name: "Customer Reports",
      // //   hasChildren: true,
      // //   href: "",
      // //   children: [
      // //     {
      // //       name: "WIPAY",
      // //       href: "/dashboard/other-reports/wipay",
      // //     },
      // //   ],
      // // },
      // // {
      // //   icon: <BiBox />,
      // //   name: "Commission",
      // //   hasChildren: false,
      // //   href: "/dashboard/commissions",
      // // },
      // // {
      // //   icon: <FiBox />,
      // //   name: "Performance",
      // //   hasChildren: false,
      // //   href: "/dashboard/performance-management",
      // // },
      // {
      //   // for bank
      //   icon: <FiBox />,
      //   name: "Card Network",
      //   hasChildren: false,
      //   href: "/dashboard/card-network",
      // },
      {
        icon: <FiSettings />,
        name: "Settings",
        hasChildren: true,
        href: "",
        children: [
          // {
          //   name: hasAccount ? "Update Settlement Bank" : "Add Settlement Bank",
          //   href: "/dashboard/settings/change-bank-account",
          // },
          {
            name: "Change PIN",
            href: "/dashboard/settings/change-pin",
          },
          {
            name: "Change Password",
            href: "/dashboard/settings/change-password",
          },
          {
            name: "Reset Transaction PIN",
            href: "/dashboard/settings/set-transaction-pin",
          },
        ],
      },
    ],
  };

  if (agentRole !== USER_TYPE.AGENT) {
    const results = URLValues?.Urls?.filter((element) => {
      if (!allResellerRoutes.includes(element?.name)) {
        return true;
      }
      return false;
    });

    return { Urls: results };
  }

  if (agentRole === USER_TYPE.AGENT) {
    const updatedURLValues = URLValues?.Urls?.filter(
      (x) => x?.name !== "Wallets"
    );
    return { Urls: updatedURLValues };
  }
  return URLValues;
};
