import React from "react";
import * as XLSX from "xlsx";
import Export from "./Style/ExportStyle.module.css";
// import "./_export_style.scss";

export const downloadExcel = (apiData) => {
  const worksheet = XLSX.utils.json_to_sheet(apiData?.data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, apiData?.fileName + `${apiData?.ext || ".xlsx"}`);
};

export const ExportToExcel = (apiData, fileName) => {
  return (
    <button
      className={Export.Export_Button}
      onClick={() => downloadExcel(apiData)}
    >
      Export {`${apiData?.button_name || "Report"}`}
    </button>
  );
};
