import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./AccountSetup.scss";
import { Dialog, Slide } from "@mui/material";
import { useDispatch } from "react-redux";
import { CurrentUser } from "../../utils/redux/Auth/AuthSlice";
import { useSelector } from "react-redux";
import AddPin from "../../sharedComponent/modals/addPin/AddPin";

const Transition = React?.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function AccountSetUp() {
  const [openAddPin, setOpenAddPin] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((x) => x?.auth?.loading);

  useEffect(() => {
    dispatch(CurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAddPin]);

  return (
    <div className="account-setup">
      <Box component="div" className="top-indicator-container">
        {user === true && <small>Updating User...</small>}
        <Box component="div" className="top-indicator">
          <Box className="top-indicator-left-wrapper">
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                margin: "0px 10px",
              }}
            ></Box>
            <Box className="top-indicator-info">
              <p>Create Your 4 Digit Transaction PIN</p>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              className="top-indicator-btn"
              onClick={() => {
                setOpenAddPin(true);
              }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        fullScreen
        open={openAddPin}
        onClose={() => setOpenAddPin(false)}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            justifyContent: "flex-end",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "485px",
              backgroundColor: "#F9FAFC",
            },
          },
          background: "rgba(229,229,229,0.4)",
        }}
        className="settlement-setup-dialog"
      >
        <AddPin closeModal={() => setOpenAddPin(false)}/>
      </Dialog>
    </div>
  );
}
