import React from "react";
import DatePicker from "react-datepicker";
import { HiOutlineCalendar } from "react-icons/hi";
import "./style/style.css";
import "./style/react-datepicker.css";
import { FiChevronDown } from "react-icons/fi";

function DateComponent({ date, setDate, maxDate }) {
  return (
    <div className="date_picker_container">
      <HiOutlineCalendar
        size={18}
        className="outline_calendar"
      />
      <DatePicker
        className="date_picker"
        selected={date ? date : new Date()}
        onChange={(d) => setDate(d)}
        maxDate={maxDate || new Date()}
        placeholderText="Select Date"
      />
      <FiChevronDown size={18} className="outline_calendar" />
    </div>
  );
}

export default DateComponent;
