export const FormatCurrency = (amount, currency) => {
  const value = new Intl.NumberFormat("en-SL", {
    minimumFractionDigits: 2,
    style: "currency",
    currency: currency || "SLE",
  }).format(amount || 0);

  //
  return value;
};
