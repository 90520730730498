import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ReportsService } from "./ReportsService";

export const GetAccessAgentTransactionsCharts = createAsyncThunk(
  "ls/GetAccessAgentTransactions",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AccessBankAgentTransactionsCharts(
          payload
        );
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const GetAccessAgentTransactions = createAsyncThunk(
  "ls/GetAccessAgentTransactions",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AccessBankAgentTransactions(
          payload
        );
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const GetAccessAgentTransactionsDownlines = createAsyncThunk(
  "ls/GetAccessAgentTransactions",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AccessBankAgentTransactionsDownline(
          payload
        );
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const ListTransaction = createAsyncThunk(
  "ls/ListTransaction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().ListTransaction(
        payload
      );
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const AgentListTransaction = createAsyncThunk(
  "ls/ListTransaction",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AgentListTransaction(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AgentListTransactionCsv = createAsyncThunk(
  "ls/AgentListTransactionCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().AgentListTransaction(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const MainWalletReport = createAsyncThunk(
  "ls/MainWalletReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().MainWallet(payload);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const MainWalletReportCsv = createAsyncThunk(
  "ls/MainWalletReportCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().MainWallet(payload);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const CommissionWalletReport = createAsyncThunk(
  "ls/MainWalletReport",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().CommissionWalletReport(payload);
      return response?.data;
    } catch (error) {
      return error?.response;
    }
  }
);

export const CusstomerListing = createAsyncThunk(
  "ls/CusstomerListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().CusstomerListing(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const SingleCustomerListing = createAsyncThunk(
  "ls/SingleCustomerListing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ReportsService.reportsModule().SingleCustomerListing(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const TransactionSummary = createAsyncThunk(
  "ls/TransactionSummary",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().TransactionSummary(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AirtimeReports = createAsyncThunk(
  "ls/AirtimeReports",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().AirtimeReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BillsReports = createAsyncThunk(
  "ls/AirtimeReports",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().BillsReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DataReports = createAsyncThunk(
  "ls/DataReports",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().DataReports(
        payload
      );
      //console.log(response)
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const AirtimeReportsCsv = createAsyncThunk(
  "ls/AirtimeReportsCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().AirtimeReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const BillsReportsCsv = createAsyncThunk(
  "ls/BillsReportsCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().BillsReports(
        payload
      );
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const DataReportsCsv = createAsyncThunk(
  "ls/DataReportsCsv",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ReportsService.reportsModule().DataReports(
        payload
      );
      //console.log(response)
      return response?.data;
    } catch (error) {
      //  exitReponse(error);
      return error?.response;
    }
  }
);

export const ReportsSlice = createSlice({
  name: "Reports",
  initialState: "",
  extraReducers: {
    [GetAccessAgentTransactions.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetAccessAgentTransactions.fulfilled]: (state, action) => {
      return {
        ...state,
        agent_transactions: action.payload,
        loading: false,
      };
    },
    [GetAccessAgentTransactions.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [DataReports.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DataReports.fulfilled]: (state, action) => {
      return {
        ...state,
        DataListReports: action.payload,
        loading: false,
      };
    },
    [DataReports.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BillsReports.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BillsReports.fulfilled]: (state, action) => {
      return {
        ...state,
        BillsListReports: action.payload,
        loading: false,
      };
    },
    [BillsReports.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AirtimeReports.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AirtimeReports.fulfilled]: (state, action) => {
      return {
        ...state,
        AirtimeReports: action.payload,
        loading: false,
      };
    },
    [AirtimeReports.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [DataReportsCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [DataReportsCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        DataListReportsCsv: action.payload,
        loading: false,
      };
    },
    [DataReportsCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [BillsReportsCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BillsReportsCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        BillsListReportsCsv: action.payload,
        loading: false,
      };
    },
    [BillsReportsCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AirtimeReportsCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AirtimeReportsCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        AirtimeReportsCsv: action.payload,
        loading: false,
      };
    },
    [AirtimeReportsCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ListTransaction.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [ListTransaction.fulfilled]: (state, action) => {
      return {
        ...state,
        TransactionListingReport: action.payload,
        loading: false,
      };
    },
    [ListTransaction.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [AgentListTransactionCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [AgentListTransactionCsv.fulfilled]: (state, action) => {
      return {
        ...state,
        TransactionListingReportCsv: action.payload,
        loading: false,
      };
    },
    [AgentListTransactionCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [MainWalletReport.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [MainWalletReport.fulfilled]: (state, action) => {
      return { ...state, MainWalletReport: action.payload, loading: false };
    },
    [MainWalletReport.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [MainWalletReportCsv.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [MainWalletReportCsv.fulfilled]: (state, action) => {
      return { ...state, MainWalletReportCsv: action.payload, loading: false };
    },
    [MainWalletReportCsv.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [CommissionWalletReport.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CommissionWalletReport.fulfilled]: (state, action) => {
      return { ...state, CommissionReport: action.payload, loading: false };
    },
    [CommissionWalletReport.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [SingleCustomerListing.pending]: (state) => {
      return {
        ...state,
        loadingCustomer: true,
      };
    },
    [SingleCustomerListing.fulfilled]: (state, action) => {
      return {
        ...state,
        SingleCustomer: action.payload,
        loadingCustomer: false,
      };
    },
    [SingleCustomerListing.rejected]: (state, action) => {
      return {
        ...state,
        loadingCustomer: false,
        error: action.payload,
      };
    },

    [CusstomerListing.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [CusstomerListing.fulfilled]: (state, action) => {
      return {
        ...state,
        CusstomerListingReport: action.payload,
        loading: false,
      };
    },
    [CusstomerListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [TransactionSummary.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [TransactionSummary.fulfilled]: (state, action) => {
      return {
        ...state,
        TransactionSummaryReport: action.payload,
        loading: false,
      };
    },
    [TransactionSummary.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export default ReportsSlice.reducer;
