import React from "react";
import "./style/style.css";
import "./style/react-datepicker.css";
import { IoSearch } from "react-icons/io5";

function SearchComponent({ value, onChange, name }) {
  return (
    <div className="search_comp_container">
      <IoSearch size={18} color={"#BDBDBD"} />
      <input
        value={value}
        onChange={onChange}
        placeholder="Search"
        type="text"
        name={name}
      />
    </div>
  );
}

export default SearchComponent;
