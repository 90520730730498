import React from "react";
import ACCESS_BANK from "./access_bank.png";

function Logo({ ...rest }) {
  const { className } = rest;
  return (
    <img
      src={ACCESS_BANK}
      className={`alpha-logo ${className}`}
      alt="Access Bank"
    />
  );
}

export default Logo;
