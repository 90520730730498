import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [open, closeModal] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [success, setSuccess] = useState(false);

  function closeAppModal() {
    closeModal(!open);
  }

  function SiteRoute(routes) {
    setRoutes(routes);
  }

  const openSuccess = () => {
    setSuccess(true);
  };

  const closeSuccess = () => {
    setSuccess(false);
  };

  const startAction = ({ type, details, message }) => {
    setIsOpen(true);
    setDetails(details);
    setSuccessMessage(message);
  };

  const endAction = () => {
    setIsOpen(false);
    setDetails({});
  };
  return (
    <AppContext.Provider
      value={{
        startAction,
        endAction,
        details,
        isOpen,
        open,
        successMessage,
        closeAppModal,
        SiteRoute,
        routes,
        openSuccess,
        closeSuccess,
        success
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
