import api, { formApi } from "../api";

const ProductsModule = () => {
  return {
    ///Access Bank - Sierra Leone - Start

    // ----> Airtime
    SingleAirtime: function (data) {
      return api.post("airtime/Airtime/accessbank/buy-airtime", data);
    },

    NetworkProviders: function (data) {
      return api.get("airtime/Airtime/accessbank/providers");
    },

    NetworkProvidersPlans: function (data) {
      return api.get(
        `airtime/Airtime/accessbank/plans/${data}?providerId=${data}`
      );
    },

    // ----> Data

    SingleAirtimeData: function (data) {
      return api.post("data/Service/accessbank/buy-data", data);
    },

    NetworkProvidersData: function (data) {
      return api.get("data/Service/accessbank/providers");
    },

    NetworkProvidersPlansData: function (data) {
      return api.get(
        `data/Service/accessbank/plans/${data}?providerId=${data}`
      );
    },

    // ----> Electricity
    SingleElectricity: function (data) {
      return api.post("bills/BillerService/accessbank/buy-electricity", data);
    },

    ElectiricityProvider: function (data) {
      return api.get("bills/BillerService/accessbank/providers");
    },

    ElectricityProviderPlans: function (data) {
      return api.get(
        `bills/BillerService/accessbank/plans/${data}?providerId=${data}`
      );
    },

    ///Access Bank - Sierra Leone - Ends

    BulkAirtime: function (data) {
      // const searchParams = new URLSearchParams(window.location.search);
      const q =
        Math.floor(1000 + Math.random() * 9000) +
        `${data?.otp}` +
        Math.floor(1000 + Math.random() * 9000);
      return formApi.post(
        `airtime/Airtime/sendairtime/bulk?bulkAirtime=${q}`,
        data?.file
      );
    },

    SingleData: function (data) {
      return api.post("data/Service/senddata", data);
    },

    GetDataPlans: function (data) {
      return api.get(
        `data/Service/getdataplan?MobileNetwork=${data || 1}`,
        data
      );
    },

    GetProviders: function (data) {
      return api.get(`bills/BillerService/providers?serviceCode=${data}`, data);
    },

    GetProducts: function (data) {
      const { service_code, product_code } = data;
      return api.get(
        `bills/BillerService/products?serviceCode=${service_code}&providerCode=${product_code}`,
        data
      );
    },

    VerifyMeter: function (data) {
      return api.post("bills/BillerService/verifyelectricityymeter", data);
    },

    // SingleElectricity: function (data) {
    //   return api.post("bills/BillerService/payelectricity", data);
    // },

    VerifyCable: function (data) {
      return api.post("bills/BillerService/verifycabletv", data);
    },

    SingleCable: function (data) {
      return api.post("bills/BillerService/paycabletv", data);
    },

    ListTransaction: function (data) {
      // ////console.log("data2", data);
      // return api.post("wallet/Wallet/wallettransactions", data);
      return api.post("wallet/Wallet/walletwithdownlinetransactions", data);
    },

    TransactionDetails: function (data) {
      // ////console.log("data2", data);
      return api.post(`/Admin/reports/airtime/${data?.ref}`, data);
    },

    MainWallet: function (data) {
      return api.post("wallet/Wallet/walletmovements", data);
    },

    CommissionWalletReport: function (data) {
      ////console.log("data new stuff", data);
      return api.get(
        `/wallet/Wallet/commissionmovements?PageSize=${data.pageSize}&CurrentPage=${data.currentPage}&keyword=${data.keyword}&ServiceType=${data.ServiceType}&StartDate=${data.startDate}&EndDate=${data.endDate}`
      );
      // return api.get(`/Wallet/commissionmovements?keyword=${data.keyword}&ServiceType=${data.ServiceType}&PageSize=${data.PageSize}&CurrentPage=${data.CurrentPage}`);
    },

    CusstomerListing: function (data) {
      // ////console.log("data2", data);
      return api.get(
        `identity/Agent/customerlisting?agentrole=${data?.agentrole}`
      );
    },

    TransactionSummary: function (data) {
      // ////console.log("data2", data);
      return api.get(
        `identity/Agent/summary`
        // ?AgentRoleId=${data?.AgentRoleId}
      );
    },
  };
};

export const ProductsService = {
  productsModule: ProductsModule,
};
