import React from "react";
import "./Styles/Style.scss";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DropDownComponent from "../dropdown/DropDownComponent";

const TableActionsCustom = ({
  last_page,
  present_page,
  pageSize,
  click,
  setPageSize,
  hideOnMobile = false,
  totalRows,
}) => {
  const array = [
    {
      name: 10,
      key: 10,
    },

    {
      name: 20,
      key: 20,
    },

    {
      name: 50,
      key: 50,
    },

    {
      name: "All",
      key: totalRows,
    },
  ];


  return (
    <div
      className={`flex table-controls pagination-controls ${
        hideOnMobile ? "hide-on-mobile" : ""
      } `}
    >
      <div className="pag_drop" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <p>Showing</p>
        <DropDownComponent
          array={array}
          onChange={(e) => setPageSize(e.target.value)}
          value={pageSize}
          name={"demo"}
          maxWidth={"100px"}
        />
        <p>Entries</p>
      </div>
      <p className="px-4">
        {/* Viewing {/* currentPage */}
        {/* {present_page} of {last_page} */}
        {/* showing 1 to {limitHandler} of {totalRows} */}
      </p>

      <div className="flex pagination-holder">
        <button
          disabled={present_page === 1 && true}
          onClick={() => click(present_page - 1)}
          style={{ cursor: "pointer" }}
          className="pagination-movement"
        >
          <IoIosArrowBack color="#828282" />
        </button>{" "}
        <button
          className="pagination-movement_active"
          disabled={last_page === present_page && true}
          style={{ cursor: "pointer", fontWeight: "400" }}
        >
          {present_page}
        </button>
        <button
          className="pagination-movement"
          disabled={present_page - last_page === 0 && true}
          onClick={() => click(present_page + 1)}
          style={{ cursor: present_page - last_page === 0 ? "not-allowed": "pointer" }}
        >
          <IoIosArrowForward color="#828282" />
        </button>
      </div>
    </div>
  );
};

export default TableActionsCustom;
