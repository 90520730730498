import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import "./Header.css";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BreadCrumb from "../common/CustomBreadCrumbs";
// import Logo from "../../assets/img/logo.svg";

import { CgMenuLeftAlt as MenuIcon } from "react-icons/cg";

// import { defaultUser } from "../data/AuthUser";
import UserAvatar from "../common/UserAvatar";
import { MdOutlineCancel } from "react-icons/md";
import { theme } from "../../context/ThemeProvider";
import { logout } from "../../redux/actions/AuthActions";
import { IoNotificationsOutline, IoNotificationsSharp } from "react-icons/io5";
import { Dialog, Slide, Typography } from "@mui/material";
import { InvoiceNotification } from "../../utils/redux/Invoice/InvoiceSlice";
import { FormatDateTime } from "../../utils/GlobalUtils/DateFormater";
import Logo from "../../assets/img/Logo";
import { SiteParams } from "../common/CustomListItem";
import { DrawerURLs } from "../Drawer/DrawerContext/DrawerURLs";
import { AppContext } from "../../utils/AppContext/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { routes } = useContext(AppContext);
  // const [titles, setTitles] = useState({});
  // const props_ = DrawerURLs()?.Urls?.map((url) => {
  //   const children = url?.children?.map((u) => {
  //     const { selectedDrawer, selectedDrawerChildren } = SiteParams({
  //       text: u?.name,
  //       href: u?.href,
  //     });

  //     console.log({ selectedDrawer, selectedDrawerChildren });

  //     return "children";
  //   });

  //   // setTitles({ selectedDrawer, selectedDrawerChildren });

  //   // console.log({ selectedDrawer, selectedDrawerChildren, url });
  //   return "props_";
  // });
  // // const { selectedDrawer, selectedDrawerChildren } = ;

  // console.log({ props_, titles });
  // // const authenticatedUser = useSelector(
  // //   (state) =>
  // //     state.AuthReducer.userProfile ||
  // //     JSON.parse(sessionStorage.getItem("user_profile"))
  // // );

  console.log({ routes });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const toggleProfileMenu = (show) => (event) => {
    if (show) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleLogout = (event) => {
    localStorage.clear();
    navigate("/");
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar
        position="relative"
        elevation={1}
        // sx={{ marginLeft: "30px" }}
        color="secondary"
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            gap: "10px",
          }}
        >
          <Logo className="display_on_sm" />
          {/* <nav id="quick-nav">
            <BreadCrumb
              breadcrumbs={[
                <Typography key="1" color="inherit">
                  Agent Management
                </Typography>,
                <Typography key="2" color="primary.main">
                  Agents
                </Typography>,
              ]}
            />
          </nav> */}
          <div></div>

          {/* <img
            src={Logo}
            alt="UCARD"
            id="drawer-logo"
            className="display_on_sm"
          /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div onClick={() => setIsOpen(!isOpen)}>
              <IoNotificationsSharp color={"#C7C6CD"} fontSize={"20px"} />
            </div>
            <div>
              <MuiButton
                sx={{ padding: "10px 15px 10px 20px" }}
                id="header-profile-button"
                aria-controls={open ? "header-profile-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={toggleProfileMenu(true)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  // gap={"15px"}
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  <UserAvatar
                    size="32px"
                    fontSize="13px"
                    // firstname={authenticatedUser?.first_name || ""}
                    // lastname={authenticatedUser?.last_name || ""}
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{ ml: "13px", textTransform: "capitalize" }}
                  >
                    <h3
                      style={{
                        margin: "0",
                        fontWeight: "800",
                        fontSize: "14px",
                      }}
                    >
                      {/* {`${authenticatedUser?.first_name || "-"} ${
                      authenticatedUser?.last_name || "-"
                    }`} */}
                    </h3>
                    <span style={{ fontSize: "12px" }}>
                      {/* {authenticatedUser?.my_role?.role || "-"} */}
                    </span>
                  </Stack>
                </Stack>
              </MuiButton>
              <Menu
                id="header-profile-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={toggleProfileMenu(false)}
                MenuListProps={{
                  "aria-labelledby": "header-profile-button",
                }}
                sx={{
                  "& .MuiMenu-paper": {
                    borderRadius: "15px",
                    boxShadow: "0px 5px 20px rgba(108, 117, 125, 0.4)",
                  },
                }}
              >
                <MenuItem onClick={toggleProfileMenu(false)}>
                  {/* <MuiButton
                  href='/profile'
                  sx={{
                    p: 0,
                    m: 0,
                    textTransform: "capitalize",
                    color: "inherit",
                    fontSize: "inherit",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Edit Profile
                </MuiButton> */}
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>

            <IconContext.Provider
              value={{ size: "28px", color: `${theme.palette.primary.main}` }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={props.toggler}
                sx={{ ml: -1, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </IconContext.Provider>
          </div>
        </Toolbar>
      </AppBar>
      {/* <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            justifyContent: "flex-end",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
          background: "rgba(229,229,229,0.4)",
        }}
        className="manage-farm-lots-dialog"
      >
        <MdOutlineCancel
          onClick={() => setIsOpen(!isOpen)}
          fontSize={"1.4rem"}
          className={"cancel_icon"}
          color={"#171832"}
        />
        <Payment />
      </Dialog> */}
    </React.Fragment>
  );
}

export default Header;

function Payment(props) {
  const dispatch = useDispatch();
  const message = useSelector((state) => state?.invoice);
  useEffect(() => {
    dispatch(InvoiceNotification());
  }, []);

  const navigate = useNavigate();

  return (
    <div className="not-wrapper not_form_invoice">
      <h2>Notification</h2>
      <div>
        <div>
          {message?.loading && <p>Loading...</p>}
          {/* <small>January, 1st 2023</small> */}
          {message?.notification?.map((x, id) => (
            <div className={x?.read_at === null && "not_not_read"}>
              {/* <h5>Title</h5> */}
              <p>{x?.data?.message}</p>
              <small>{FormatDateTime(x?.created_at, "LLL")}</small>
              <span>
                {x?.data?.invoice?.status === "OPEN" && (
                  <a
                    href={`/dashboard/invoice/open/${x?.data?.invoice?.id}?invoice=${x?.id}`}
                  >
                    <p style={{ color: "#2FA566" }}>Make Payment</p>
                  </a>
                )}
                {x?.data?.invoice?.status === "CLOSED" && (
                  <a
                    href={`/dashboard/invoice/closed/${x?.data?.invoice?.id}?invoice=${x?.id}`}
                  >
                    <p style={{ color: "#F32D2D" }}>View Invoice</p>
                  </a>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
