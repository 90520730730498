import "./index.scss";
import "../../components/Account-setup/AccountSetup.scss";
import { GetLocalStorageUser } from "../../utils/GlobalUtils/GetLocalStorageValues";
import AccountSetUp from "../../components/Account-setup/AccountSetup";
import DashboardTemplate from "./DBTemplate";
import DashboardHomePage from "./DBHomepage";

export const VERIFICATION_INCLUDES_PIN_CREATION = () => {
  const { pendingVerifications } = GetLocalStorageUser();

  return pendingVerifications?.includes("PIN_CREATION");
};

export default function DashboardIndexSection() {
  return (
    <DashboardTemplate>
      {VERIFICATION_INCLUDES_PIN_CREATION() ? (
        <AccountSetUp />
      ) : (
        <DashboardHomePage />
      )}
    </DashboardTemplate>
  );
}
