import React from "react";
import "./index.scss";
import "../../components/Account-setup/AccountSetup.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Bank from "../../../src/assets/img/dashboard_bank_details.svg";
import AccountBalance from "../../../src/assets/img/dashboard_account_balance.svg";
import { useNavigate } from "react-router";
import { QuickActions } from "./QuickActionsList";
import DBPerformanceTracking from "./DBPerformance";
import { FormatCurrency } from "../../utils/GlobalUtils/Currency";
import { GetLocalStorageUser } from "../../utils/GlobalUtils/GetLocalStorageValues";
import { USER_TYPE } from "../../utils/GlobalUtils/Enums";

export default function DashboardHomePage({ children }) {
  const navigate = useNavigate();

  const {
    businessName,
    vwAccountNumber,
    vwBankName,
    walletBalance,
    agentRole,
  } = GetLocalStorageUser();
  return (
    <section>
      <>
        {children}

        <Box disabled={true} className={`wallet-cards2 `}>
          <Grid container spacing={1} className="class-grid">
            <Grid item xs={12} md={6}>
              <Box className="card-wrapper">
                <Box className="card-top">
                  <Box className="card-top-info">
                    <Box>
                      <div>
                        <Typography className="card-top-info-text-access">
                          <b>{businessName}</b>
                        </Typography>
                        <Typography className="card-top-info-text-access">
                          <b>Account Number:</b> {vwAccountNumber}
                        </Typography>
                        <Typography className="card-top-info-text-access">
                          <b>Bank:</b> {vwBankName}
                        </Typography>
                      </div>
                    </Box>
                    <img
                      src={Bank}
                      alt="wallet"
                      className="card-top-info-img"
                    />
                  </Box>
                  {/* <Typography className="card-amount">
                    {FormatCurrency(data?.walletBalance)}
                  </Typography> */}
                  {/* <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 3fr",
                      width: "100%",
                    }}
                  >
                    <Box></Box>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
            {agentRole === USER_TYPE.AGENT && (
              <Grid item xs={12} md={6}>
                <Box className="card-wrapper">
                  <Box className="card-top">
                    <Box className="card-top-info">
                      <Box>
                        <div>
                          <Typography className="card-top-info-text">
                            Account Balance
                          </Typography>
                          <Typography className="card-amount">
                            {FormatCurrency(walletBalance)}
                          </Typography>
                        </div>
                      </Box>
                      <img
                        src={AccountBalance}
                        alt="wallet"
                        className="card-top-info-img"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <div className="q_quick">
            <h3>Quick Actions</h3>
            <div className={"quick_actions"}>
              {QuickActions()?.Actions?.map((q) => (
                <div
                  className={"quick_action"}
                  onClick={() => navigate(q?.url)}
                >
                  <img src={q?.icon} alt={q?.name} />
                  <p>{q?.name}</p>
                </div>
              ))}
            </div>
          </div>
          <DBPerformanceTracking fromDashboard={true} />
        </Box>
      </>
    </section>
  );
}
