import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ProductsService } from "./ProductsService";

///Access Bank - Sierra Leone - Start

export const SingleAirtimeRecharge = createAsyncThunk(
  "ls/SingleAirtimeRecharge",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().SingleAirtime(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An Error Occurred While Performing Operation"
      );
      rejectWithValue(error);
      return error;
    }
  }
);

export const GetNetworkProviders = createAsyncThunk(
  "ls/GetNetworkProviders",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().NetworkProviders(
        payload
      );

      console.log(response);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      rejectWithValue(error);
      return error;
    }
  }
);

export const GetNetworkProvidersPlans = createAsyncThunk(
  "ls/GetNetworkProvidersPlans",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ProductsService.productsModule().NetworkProvidersPlans(payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      rejectWithValue(error);
      return error;
    }
  }
);

export const SingleDataRecharge = createAsyncThunk(
  "ls/SingleDataRecharge",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().SingleAirtimeData(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An Error Occurred While Performing Operation"
      );
      //  exitReponse(error);
      rejectWithValue(error);
      return error;
    }
  }
);

export const GetNetworkProvidersData = createAsyncThunk(
  "ls/GetNetworkProviders",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ProductsService.productsModule().NetworkProvidersData(payload);

      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      rejectWithValue(error);
      return error;
    }
  }
);

export const GetNetworkProvidersPlansData = createAsyncThunk(
  "ls/GetNetworkProvidersPlans",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ProductsService.productsModule().NetworkProvidersPlansData(
          payload
        );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      rejectWithValue(error);
      return error;
    }
  }
);

export const SingleElectricity = createAsyncThunk(
  "ls/SingleElectricity",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().SingleElectricity(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An Error Occurred While Performing Operation"
      );
      //  exitReponse(error);
      rejectWithValue(error);
      return error;
    }
  }
);

export const GetElectricityProviders = createAsyncThunk(
  "ls/GetNetworkProviders",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ProductsService.productsModule().ElectiricityProvider(payload);

      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      rejectWithValue(error);
      return error;
    }
  }
);

export const GetElectricityProviderPlan = createAsyncThunk(
  "ls/GetNetworkProvidersPlans",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response =
        await ProductsService.productsModule().ElectricityProviderPlans(
          payload
        );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      rejectWithValue(error);
      return error;
    }
  }
);

///Access Bank - Sierra Leone - Ends

export const BulkAirtimeRecharge = createAsyncThunk(
  "ls/BulkAirtimeRecharge",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().BulkAirtime(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An Error Occurred While Performing Operation"
      );
      //  exitReponse(error);
      return error;
    }
  }
);

export const DataPlans = createAsyncThunk(
  "ls/DataPlans",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().GetDataPlans(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error;
    }
  }
);

export const GetProviders = createAsyncThunk(
  "ls/GetProviders",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().GetProviders(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error;
    }
  }
);

export const GetProducts = createAsyncThunk(
  "ls/GetProducts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().GetProducts(
        payload
      );
      return response?.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      return error;
    }
  }
);

export const VerifyMeter = createAsyncThunk(
  "ls/VerifyMeter",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().VerifyMeter(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error;
    }
  }
);

export const VerifyCableTV = createAsyncThunk(
  "ls/VerifyMeter",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().VerifyCable(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error;
    }
  }
);

export const SingleCable = createAsyncThunk(
  "ls/SingleElectricity",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await ProductsService.productsModule().SingleCable(
        payload
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error;
    }
  }
);

export const ProductsSlice = createSlice({
  name: "Products",
  initialState: "",
  extraReducers: {
    //Access Bank
    [SingleAirtimeRecharge.pending]: (state) => {
      return {
        ...state,
        buyingAirtime: true,
      };
    },
    [SingleAirtimeRecharge.fulfilled]: (state, action) => {
      return {
        ...state,
        airtime: action.payload,
        buyingAirtime: false,
      };
    },
    [SingleAirtimeRecharge.rejected]: (state, action) => {
      return {
        ...state,
        buyingAirtime: false,
        error: action.payload,
      };
    },

    [GetNetworkProvidersPlans.pending]: (state) => {
      return {
        ...state,
        fetchingProviderPlans: true,
      };
    },
    [GetNetworkProvidersPlans.fulfilled]: (state, action) => {
      return {
        ...state,
        providerPlans: action.payload,
        fetchingProviderPlans: false,
      };
    },
    [GetNetworkProvidersPlans.rejected]: (state, action) => {
      return {
        ...state,
        fetchingProviderPlans: false,
        error: action.payload,
      };
    },

    [GetNetworkProviders.pending]: (state) => {
      return {
        ...state,
        fetchingProviders: true,
      };
    },
    [GetNetworkProviders.fulfilled]: (state, action) => {
      return {
        ...state,
        providers: action.payload,
        fetchingProviders: false,
      };
    },
    [GetNetworkProviders.rejected]: (state, action) => {
      return {
        ...state,
        fetchingProviders: false,
        error: action.payload,
      };
    },

    //Access Bank Ends

    [SingleDataRecharge.pending]: (state) => {
      return {
        ...state,
        loadingPlans: true,
        buyingData: true,
      };
    },
    [SingleDataRecharge.fulfilled]: (state, action) => {
      return {
        ...state,
        plans: action.payload,
        loadingPlans: false,
        buyingData: false,
      };
    },
    [SingleDataRecharge.rejected]: (state, action) => {
      return {
        ...state,
        loadingPlans: false,
        buyingData: false,
        error: action.payload,
      };
    },

    [DataPlans.pending]: (state) => {
      return {
        ...state,
        loadingPlans: true,
      };
    },
    [DataPlans.fulfilled]: (state, action) => {
      return {
        ...state,
        plans: action.payload,
        loadingPlans: false,
      };
    },
    [DataPlans.rejected]: (state, action) => {
      return {
        ...state,
        loadingPlans: false,
        error: action.payload,
      };
    },

    [BulkAirtimeRecharge.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [BulkAirtimeRecharge.fulfilled]: (state, action) => {
      return {
        ...state,
        bulkAirtime: action.payload,
        loading: false,
      };
    },
    [BulkAirtimeRecharge.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    // [SingleAirtimeRecharge.pending]: (state) => {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // },
    // [SingleAirtimeRecharge.fulfilled]: (state, action) => {
    //   return {
    //     ...state,
    //     singleAirtime: action.payload,
    //     loading: false,
    //   };
    // },
    // [SingleAirtimeRecharge.rejected]: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    // },

    [GetProducts.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetProducts.fulfilled]: (state, action) => {
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    },
    [GetProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [GetProviders.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GetProviders.fulfilled]: (state, action) => {
      return {
        ...state,
        providers: action.payload,
        loading: false,
      };
    },
    [GetProviders.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [VerifyMeter.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [VerifyMeter.fulfilled]: (state, action) => {
      return {
        ...state,
        verifiedData: action.payload,
        loading: false,
      };
    },
    [VerifyMeter.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [SingleElectricity.pending]: (state) => {
      return {
        ...state,
        loading: true,
        buyingElectricity: true,
      };
    },
    [SingleElectricity.fulfilled]: (state, action) => {
      return {
        ...state,
        verifiedData: action.payload,
        loading: false,
        buyingElectricity: false,
      };
    },
    [SingleElectricity.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        buyingElectricity: false,
        error: action.payload,
      };
    },
  },
});

export default ProductsSlice.reducer;
