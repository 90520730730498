import axios from "axios";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import {
  GetLocalStorageUser,
  GetLongitudeLatitude,
} from "../GlobalUtils/GetLocalStorageValues";

export let apiToken = GetLocalStorageUser("user_profile");
const Location = GetLocalStorageUser("location");

GetLongitudeLatitude();

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,

  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + apiToken?.accessToken,
    PARTNERID: "ACCESSBANKSERIEALONE",
    SOURCE: "WEB-AGENT-PORTAL",
    LONGITUDE: Location?.longitude || "",
    LATITUDE: Location?.latitude || "",
  },
});

export const formApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + apiToken?.accessToken,
    PartnerId: "ACCESSBANKSERIEALONE",
    Source: "",
    Longitude: "",
    Latitude: "",
  },
});

export const invoiceAPI = (type) => {
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": type || "application/json",
      Authorization: "Bearer " + apiToken?.accessToken,
      PartnerId: "ACCESSBANKSERIEALONE",
      Source: "",
      Longitude: "",
      Latitude: "",
    },
  });
};

invoiceAPI()?.interceptors?.request?.use(
  async function (req) {
    if (!apiToken) {
      apiToken = localStorage.getItem("user_profile")
        ? JSON.parse(localStorage.getItem("user_profile"))
        : null;
      req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
    }

    // ////console.log("res", req);

    let user = jwtDecode(apiToken?.accessToken);
    let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

    if (isExpired) {
      localStorage.removeItem("user_profile");
      localStorage.removeItem("user");
      localStorage.clear();
      window.location.replace("/");
    }

    return req;
  }
  // async function (error) {////console.log("errorMsg", error)}
);

// ////console.log(api)

api?.interceptors?.request?.use(
  async function (req) {
    if (!apiToken) {
      apiToken = localStorage.getItem("user_profile")
        ? JSON.parse(localStorage.getItem("user_profile"))
        : null;
      req.headers.Authorization = `Bearer ${apiToken?.accessToken}`;
    }

    // ////console.log("res", req);

    let user = jwtDecode(apiToken?.accessToken);
    let isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

    if (isExpired) {
      localStorage.removeItem("user_profile");
      localStorage.removeItem("user");
      window.location.replace("/");
    }

    return req;
  }
  // async function (error) {////console.log("errorMsg", error)}
);

export default api;
