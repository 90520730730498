import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import "./AddPin.scss";
import BuildingBank from "../../../assets/img/pin.svg";
import SetPin from "./verifications/SetPin";
import { FiXCircle } from "react-icons/fi";

const AddPin = ({ closeModal }) => {
  return (
    <div className="settlement-setup-wrapper">
      <AppBar elevation={1}>
        <Toolbar elevation={0}>
          <img src={BuildingBank} alt="Bank" />
          <Typography
            sx={{ flex: 1, ml: 2, color: "#000000" }}
            variant="h6"
            component="div"
            className="settlement-setup-title"
          >
            PIN Setup
          </Typography>
          <IconButton aria-label="close" onClick={() => closeModal()}>
            <FiXCircle color="var(--text-primary-color)" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box className="settlement-setup-content" sx={{ mt: 8, p: 2 }}>
        <Box className="settlement-form-container">
          <Box className="settlement-form-body">
            <SetPin onClose={() => closeModal()} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AddPin;
